import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/pro-solid-svg-icons";

import { twMerge } from "tailwind-merge";

// Utils
import { openLink, scrollLocomotive } from "utils";
import { HEADER_MENUS, SOCIAL_MEDIAS, getScrollOffset } from "utils/constants";

// Hooks
import useMediaQuery from "hooks/useMediaQuery";

// Components
import GrugSmashed from "components/GrugSmashed";

// Assets
import GrugsLairPNG from "assets/images/grugsLair.png";
import MobileMenuSVG from "assets/images/mobile-menu.svg";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.closeMobileMenu = () => setIsOpen(false);
  }, []);

  return (
    <>
      <GrugSmashed />
      <div
        className="relative z-[1] flex h-10 w-10 items-center justify-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={MobileMenuSVG} alt="grugs-lair" layout="fill" />
        <FontAwesomeIcon
          icon={faBars}
          className={twMerge(
            "absolute w-5 text-white transition-opacity duration-300",
            isOpen && "opacity-0"
          )}
        />
        <FontAwesomeIcon
          icon={faClose}
          className={twMerge(
            "absolute w-3 text-white transition-opacity duration-300",
            !isOpen && "opacity-0"
          )}
        />
      </div>
      {isOpen && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0"
          onTouchStart={() => setIsOpen(false)}
          onMouseDown={() => setIsOpen(false)}
        />
      )}
      <div
        className={twMerge(
          "fixed left-0 top-0 w-screen touch-none bg-primary600 px-4 pb-8 pt-[88px] transition-all duration-300",
          !isOpen && "-translate-y-full opacity-0"
        )}
      >
        <div className="flex flex-col gap-6">
          {HEADER_MENUS.map((each, i) => (
            <div
              key={i}
              className="cursor-pointer font-avara text-xl font-extrabold text-white"
              onClick={() => {
                setIsOpen(false);
                each.onClick();
              }}
            >
              {each.title}
            </div>
          ))}
        </div>
        <div className="mt-6 flex gap-3 text-white">
          {SOCIAL_MEDIAS.map((each, i) => (
            <div
              key={i}
              className="flex size-6 cursor-pointer items-center justify-center"
              onClick={() => openLink(each.link)}
            >
              <FontAwesomeIcon className="text-lg" icon={each.logo} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 955px)");
  const [scrolled, setScrolled] = useState(false);

  // const { connect, disconnect, address, domain } = useArgentWallet();

  let lastKnownScrollPosition = useRef(0);
  let ticking = useRef(false);

  // useEffect(() => {
  //   const scrollHandler = (event) => {
  //     lastKnownScrollPosition.current = window.scrollY;
  //     if (!ticking.current) {
  //       window.requestAnimationFrame(() => {
  //         // simple throttle
  //         const target = event.target;
  //         setScrolled((target?.scrollingElement?.scrollTop || 0) > 100);
  //         ticking.current = false;
  //       });
  //       ticking.current = true;
  //     }
  //   };
  //   setScrolled(window.scrollY > 100);
  //   window.addEventListener("scroll", scrollHandler);
  //   return () => window.removeEventListener("scroll", scrollHandler);
  // }, []);

  if (location.pathname.includes("api/")) return null;

  return isMobile !== -1 ? (
    <div
      id="NavBar"
      className={twMerge(
        "fixed top-0 z-10 w-full py-4 transition duration-500",
        isMobile ? "py-4 pl-4 pr-5" : "py-[30px]"
      )}
      style={{
        background: scrolled
          ? "rgba(11, 11, 11, 0.9)"
          : "linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(182, 199, 243, 0) 100%)",
        ...(scrolled && { backdropFilter: "blur(40px)" }),
      }}
    >
      <div
        className={twMerge(
          "mx-auto flex max-w-screen-largeDesktop items-center gap-12",
          isMobile ? "justify-between" : "px-8"
        )}
      >
        <div className="z-[1] w-[120px]">
          <img
            src={GrugsLairPNG}
            alt="grugs-lair"
            className="h-10 cursor-pointer smallDesktop:h-12"
            onClick={() => {
              scrollLocomotive(getScrollOffset().introduction, undefined, 0);
              window?.closeMobileMenu?.();
            }}
          />
        </div>
        {isMobile ? (
          <MobileMenu />
        ) : (
          <>
            <div className="flex flex-1 items-center justify-center gap-8">
              {HEADER_MENUS.map((each, i) => (
                <div
                  key={i}
                  className="cursor-pointer font-avara text-base font-bold text-white"
                  onClick={each.onClick}
                >
                  {each.title}
                </div>
              ))}
            </div>
            <div className="flex gap-3 text-white relative">
              <GrugSmashed />
              {SOCIAL_MEDIAS.map((each, i) => (
                <div
                  key={i}
                  className="flex size-8 cursor-pointer items-center justify-center"
                  onClick={() => window.open(each.link, "_blank")}
                >
                  <FontAwesomeIcon className="text-2xl" icon={each.logo} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default Header;
